<template>
  <!--
    Resource Project Calendar
  -->
  <div class="resourceProjectCalendar">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="row">
        <div class="col-4">
          <label
            for="roles"
            class="form-label"
          >Filter Roles</label>
          <Multiselect
            id="roles"
            v-model="selectedRoles"
            tag-placeholder="Not found"
            placeholder="Role"
            :options="roleHeaders"
            select-label=""
            :multiple="true"
            :taggable="false"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="mb-3"
          />
        </div>
        <div class="col-4">
          <label
            for="teams"
            class="form-label"
          >Filter Teams</label>
          <Multiselect
            id="teams"
            v-model="selectedTeams"
            tag-placeholder="Not found"
            placeholder="Team"
            :options="resourceTeams"
            select-label=""
            :multiple="true"
            :taggable="false"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="mb-3"
          />
        </div>
        <div class="col-4">
          <template v-if="!resourceView">
            <label
              for="resourcesSrch"
              class="form-label"
            >Filter Resources</label>
            <Multiselect
              id="resourcesSrch"
              v-model="selectedResources"
              tag-placeholder="Not found"
              placeholder="Resource"
              :options="resources"
              select-label=""
              :multiple="true"
              :taggable="false"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              :custom-label="resourceLabel"
              track-by="id"
              class="mb-3"
            />
          </template>
          <template v-else>
            <label
              for="projectSrch"
              class="form-label"
            >Filter Projects</label>
            <Multiselect
              id="projectSrch"
              v-model="selectedProjects"
              tag-placeholder="Not found"
              placeholder="Project"
              :options="projects"
              select-label=""
              :multiple="true"
              :taggable="false"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              :custom-label="projectLabel"
              track-by="id"
              class="mb-3"
            />
          </template>
        </div>
      </div>
      
      <div
        class="btn-group mb-2"
        role="group"
      >
        <button
          type="button"
          class="btn btn-secondary"
          @click="goBack"
        >
          Previous
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="goToday"
        >
          Today
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="goNext"
        >
          Next
        </button>
      </div>
      <div>
        <table>
          <thead>
            <template v-if="resourceView">
              <template v-if="selectedRoles.length == 0">
                <tr>
                  <th />
                  <th>{{ $t('milestone') }}</th>
                  <th
                    v-for="(entry, index) in resourceHeaders"
                    :key="`th-${ index }`"
                  >
                    {{ entry.name ? entry.name : entry.username }}
                  </th>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th />
                  <th>{{ $t('milestone') }}</th>
                  <th
                    v-for="(entry, index) in resourceWithRoleHeaders"
                    :key="`the-${ index }`"
                  >
                    {{ entry.resource.name ? entry.resource.name : entry.resource.username }} <br>
                    <i style="font-weight:normal;">{{ entry.role }}</i>
                  </th>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr v-if="selectedRoles.length == 0">
                <th />
                <th>{{ $t('milestone') }}</th>
                <th
                  v-for="(entry, index) in projectTableHeaders"
                  :key="`th-${ index }`"
                >
                  <a
                    :href="jiraHref(entry.jiraId)"
                    target="_blank"
                    style="white-space: pre-line;"
                  >
                    {{ getEntrySummary(entry) }}
                  </a>
                </th>
              </tr>
              <template v-else>
                <tr>
                  <th />
                  <th>{{ $t('milestone') }}</th>
                  <th
                    v-for="(entry, index) in projectTableHeaders"
                    :key="`the-${ index }`"
                  >
                    <a
                      :href="jiraHref(entry.project.jiraId)"
                      target="_blank"
                      style="white-space: pre-line;"
                    >
                      {{ getEntrySummary(entry.project) }}
                    </a>
                    <br>
                    <i style="font-weight:normal;">{{ entry.role }}</i>
                  </th>
                </tr>
              </template>
            </template>
          </thead>
          <tbody>
            <tr
              v-for="(cw, index) in calenderWeeks"
              :key="`cw-${ index }`"
            >
              <th
                v-tooltip="cw.date"
                :class="['cw', { 'currentWeek' : cw.week == currentWeek && cw.year == currentYear}]"
              >
                KW {{ cw.week }}
              </th>
              <td
                :class="['entryTd sticky-td', { 'currentWeek' : cw.week == currentWeek && cw.year == currentYear }]"
              >
                <div
                  v-for="(mil, index) in getMilestones(cw.week, cw.year)"
                  :key="`mil-${ index }`"
                  class="mil"
                >
                  <a
                    v-tooltip="mil.specialLeave"
                    :href="jiraHref(mil.role)"
                    target="_blank"
                    style="text-align: start;"
                  >
                    {{ mil.specialLeave }}
                  </a>
                </div>
              </td>
              <template v-if="resourceView">
                <template v-if="selectedRoles.length == 0">
                  <td
                    v-for="(ce, index) in resourceHeaders"
                    :key="`ce-${ index }`"
                    :class="['entryTd', getEffortColor(getEntry(cw.week, cw.year, ce)), { 'currentWeek' : cw.week == currentWeek && cw.year == currentYear }]"
                    @click.self="openAddModalWithWeekAndYear(cw.week, cw.year, ce)"
                  >
                    <span
                      class="percentage"
                    >{{ getEffort(getEntry(cw.week, cw.year, ce)) }}%</span>
                    <div
                      v-for="(card, index) in getEntry(cw.week, cw.year, ce)"
                      :key="`card-${ index }`"
                      :class="['holup', { 'vacSick' : card.specialLeave}, { 'holiday' : card.description == 'AT Holiday'}, {'first' : index == 0}]"
                    >
                      <template v-if="card.description == 'AT Holiday'">
                        {{ card.specialLeave }}
                      </template>
                      <template v-else-if="card.specialLeave">
                        <div
                          class="ros"
                          style="display: flex; justify-content: space-between;"
                        >
                          <span>{{ card.specialLeave }}</span>
                          <span>{{ card.specialLeaveHours * 100 / 38.5 }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div
                            class="col"
                            style="margin: 0px; text-align: start;"
                          >
                            <template v-if="!card.projectEntry.jiraId">
                              <span v-tooltip="card.projectEntry.projectName">{{ card.projectEntry.projectName }}</span>
                            </template>
                            <a
                              v-else
                              v-tooltip="card.projectEntry.projectName"
                              :href="jiraHref(card.projectEntry.jiraId)"
                              target="_blank"
                              style="text-align: start;"
                            >
                              {{ card.projectEntry.projectName }}
                            </a>
                          </div>
                          <div
                            v-if="authenticationHasRole('projectmanager')"
                            class="col-1"
                            style="margin: 0px; padding: 0px; margin-right: 5px;"
                          >
                            <font-awesome-icon
                              class="mr-1 edit-icon"
                              icon="edit"
                              @click="openEditModal(card)"
                            />
                            <span
                              v-if="card.estimatetEffort > 0"
                              style="color: #656565;"
                            >{{ card.estimatetEffort }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="(ce, index) in resourceWithRoleHeaders"
                    :key="`ce-${ index }`"
                    :class="['entryTd', getEffortColor(getEntryWithRole(cw.week, cw.year, ce)), { 'currentWeek' : cw.week == currentWeek && cw.year == currentYear}]"
                    @click.self="openAddModalWithWeekAndYearAndRole(cw.week, cw.year, ce)"
                  >
                    <div
                      v-for="(card, index) in getEntryWithRole(cw.week, cw.year, ce)"
                      :key="`card-${ index }`"
                      :class="['holup', { 'vacSick' : card.specialLeave}, { 'holiday' : card.description == 'AT Holiday'}]"
                      @click.self="openEditModal(card)"
                    >
                      <template v-if="card.description == 'AT Holiday'">
                        {{ card.specialLeave }}
                      </template>
                      <template v-else-if="card.specialLeave">
                        <div
                          class="ros"
                          style="display: flex; justify-content: space-between;"
                        >
                          <span>{{ card.specialLeave }}</span>
                          <span>{{ card.specialLeaveHours * 100 / 38.5 }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div
                            class="col"
                            style="margin: 0px; text-align: start;"
                          >
                            <template v-if="!card.projectEntry.jiraId">
                              <span v-tooltip="card.projectEntry.projectName">{{ card.projectEntry.projectName }}</span>
                            </template>
                            <a
                              v-else
                              v-tooltip="card.projectEntry.projectName"
                              :href="jiraHref(card.projectEntry.jiraId)"
                              target="_blank"
                              style="text-align: start;"
                            >
                              {{ card.projectEntry.projectName }}
                            </a>
                          </div>
                          <div
                            v-if="authenticationHasRole('projectmanager')"
                            class="col-1"
                            style="margin: 0px; padding: 0px; margin-right: 5px;"
                          >
                            <font-awesome-icon
                              class="mr-1 edit-icon"
                              icon="edit"
                              @click="openEditModal(card)"
                            />
                            <span
                              v-if="card.estimatetEffort > 0"
                              style="color: #656565;"
                            >{{ card.estimatetEffort }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </td>
                </template>
              </template>
              <template v-else>
                <td
                  v-for="(ce, index) in projectTableHeaders"
                  :key="`ce-${ index }`"
                  :class="['entryTd', { 'currentWeek' : cw.week == currentWeek && cw.year == currentYear}]"
                  @click.self="openAddModalWithWeekAndYearProject(cw.week, cw.year, ce)"
                >
                  <div
                    v-for="(card, index) in getProjectEntry(cw.week, cw.year, ce)"
                    :key="`card-${ index }`"
                    v-tooltip="card.projectEntry ? card.projectEntry.projectName : card.description"
                    :class="[{ 'vacSick' : card.specialLeave}, { 'holiday' : card.description == 'AT Holiday'}]"
                  >
                    <template v-if="card.specialLeave">
                      {{ card.specialLeave }}
                    </template>
                    <div
                      v-else
                      class="row"
                    >
                      <div
                        class="col"
                        style="margin: 0px; text-align: start;"
                      >
                        {{ card.resourceEntry.name ? card.resourceEntry.name : card.resourceEntry.username }}
                      </div>
                      <div
                        v-if="authenticationHasRole('projectmanager')"
                        class="col-1"
                        style="margin: 0px; padding: 0px; margin-right: 5px;"
                      >
                        <font-awesome-icon
                          class="mr-1 edit-icon"
                          icon="edit"
                          @click="openEditModal(card)"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="btn-group mt-2"
        role="group"
      >
        <button
          type="button"
          class="btn btn-secondary"
          @click="goBack"
        >
          Previous
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="goToday"
        >
          Today
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="goNext"
        >
          Next
        </button>
      </div>
    </template>
    <SweetModal
      ref="projectsModal"
      title="Project"
      blocking
    >
      <LoadingPlaceholder v-if="modalLoading" />
      <template v-else>
        <div class="m-form__group form-group">
          <div class="m-radio-inline">
            <label class="m-radio">
              <input
                v-model="editProject"
                type="radio"
                :name="'projectRadio'"
                :value="true"
              >
              Edit
              <span />
            </label>
            <label class="m-radio">
              <input
                v-model="editProject"
                type="radio"
                :name="'projectRadio'"
                :value="false"
              >
              Add
              <span />
            </label>
          </div>
        </div>
        <div class="form-group">
          <template v-if="editProject">
            <label>{{ $t('project') }}</label>
            <Multiselect
              id="projectSelect"
              v-model="project"
              tag-placeholder="Not found"
              placeholder="Project"
              :options="projects"
              select-label=""
              :multiple="false"
              :taggable="false"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              :custom-label="projectLabel"
              class="mb-3"
            />
          </template>
          <hr class="m-0 mb-3">
          <label>Jira ID</label>
          <input
            v-model="project.jiraId"
            v-focus
            class="form-control"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('weather.summary') }}</label>
          <input
            v-model="project.projectName"
            v-focus
            class="form-control"
            type="text"
          >
          <span style="color: red;">If Jira ID is set, the Jira summary will overwrite custom summary.</span>
          <template v-if="!hasChilds(project.id)">
            <hr class="m-0 mb-3">
            <label>Child Projects</label>
            <Multiselect
              id="childProjectSelect"
              v-model="childProjects"
              tag-placeholder="Not found"
              placeholder="Projects"
              :options="projects"
              :multiple="true"
              :taggable="true"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              :custom-label="projectLabel"
              track-by="id"
              class="mb-3"
            />
          </template>
          <template v-else>
            <div class="m-0 mb-3" />
            <span><i>Parent: {{ getParent(project.id).projectName }}</i></span>
          </template>
          <div class="m-0 mb-3" />
          <template v-if="editProject">
            <label>Active</label>
            <input
              v-model="project.isActive"
              class="ml-3 mt-1"
              type="checkbox"
            >
          </template>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="editProject ? updateProject() : addProject()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>{{ $t('save') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3 mr-3"
        @click="$refs.projectsModal.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />
        {{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>

    <SweetModal
      ref="resourcesModal"
      title="Resource"
      blocking
    >
      <LoadingPlaceholder v-if="modalLoading" />
      <template v-else>
        <div class="m-form__group form-group">
          <div class="m-radio-inline">
            <label class="m-radio">
              <input
                v-model="editResource"
                type="radio"
                :name="'resourceRadio'"
                :value="true"
              >
              Edit
              <span />
            </label>
            <label class="m-radio">
              <input
                v-model="editResource"
                type="radio"
                :name="'resourceRadio'"
                :value="false"
              >
              Add
              <span />
            </label>
          </div>
        </div>
        <div class="form-group">
          <template v-if="editResource">
            <label>Resource</label>
            <Multiselect
              id="resourceSelect"
              v-model="resource"
              tag-placeholder="Not found"
              placeholder="Resource"
              :options="resources"
              select-label=""
              :multiple="false"
              :taggable="false"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              :custom-label="resourceLabel"
              class="mb-3"
            />
          </template>
          <hr class="m-0 mb-3">
          <label>Username</label>
          <input
            v-model="resource.username"
            v-focus
            class="form-control"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>Visible Name</label>
          <input
            v-model="resource.name"
            v-focus
            class="form-control"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>Email</label>
          <input
            v-model="resource.email"
            v-focus
            class="form-control"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>Team</label>
          <ComboBox
            v-model="resource.team"
            :data-source="resourceTeams"
            :placeholder="'Select a team...'"
            class="form-control"
          />
          <hr class="m-0 mb-3">
          <template v-if="editResource">
            <label>Active</label>
            <input
              v-model="resource.isActive"
              class="ml-3 mt-1"
              type="checkbox"
            >
          </template>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        :disabled="!resource.team"
        @click="editResource ? updateResource() : addResource()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>{{ $t('save') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3 mr-3"
        @click="$refs.resourcesModal.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />
        {{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>

    <SweetModal
      ref="calendarModal"
      :title="openCalendarAddModal ? 'Add Entry' : 'View / Edit Entry'"
      blocking
    >
      <template v-if="entry">
        <form>
          <div class="form-group">
            <label>Task:</label>
            <toggle-button
              id="toggler"
              v-model="issueToggle"
              :labels="{ checked: 'Issue Key', unchecked: 'Project Task' }"
              :color="{ checked: 'gray', unchecked: 'green' }"
              :width="100"
              :height="30"
              :font-size="10"
              class="mb-1 ml-3"
            />
            <div class="row">
              <div class="col">
                <label>{{ $t('calendarWeek') }}</label>
                <input
                  v-model="entry.calendarWeek"
                  v-focus
                  :disabled="!authenticationHasRole('projectmanager')"
                  class="form-control"
                  type="number"
                >
              </div>
              <div
                v-if="openCalendarAddModal"
                class="col"
              >
                <label>Number of weeks (default 1)</label>
                <input
                  v-model="entry.repeatForWeeks"
                  v-focus
                  :disabled="!authenticationHasRole('projectmanager')"
                  class="form-control"
                  type="number"
                >
              </div>
              <div class="col">
                <label>{{ $t('year') }}</label>
                <input
                  v-model="entry.year"
                  v-focus
                  :disabled="!authenticationHasRole('projectmanager')"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
            <hr class="m-0 mb-3">
            <template v-if="!issueToggle">
              <label>{{ $t('project') }}</label>
              <Multiselect
                id="entryProjectSelect"
                v-model="newProjectEntry"
                tag-placeholder="Not found"
                placeholder="Project"
                :options="projects.filter(x => x.isActive)"
                select-label=""
                :multiple="false"
                :taggable="false"
                :hide-selected="true"
                :searchable="true"
                :close-on-select="true"
                :custom-label="projectLabel"
                class="mb-3"
              />
              <span
                v-if="newProjectEntry && !newProjectEntry.isActive"
                style="color: red;"
              >Project is inactive.</span>
            </template>
            <template v-else>
              <label>Jira Issue</label>
              <input
                v-model="entry.jiraIssue"
                class="form-control"
              >
            </template>
            <div class="m-0 mb-3" />
            <label>Resource</label>
            <Multiselect
              id="entryProjectSelect"
              v-model="newResourceEntry"
              tag-placeholder="Not found"
              placeholder="Resource"
              :options="resources.filter(x => x.isActive)"
              select-label=""
              :multiple="false"
              :taggable="false"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              :custom-label="resourceLabel"
              class="mb-3"
            />
            <span
              v-if="newResourceEntry && !newResourceEntry.isActive"
              style="color: red;"
            >Resource is inactive.</span>
            <div class="m-0 mb-3" />
            <label>{{ $t('resourceEffort') }}</label>
            <input
              v-model="entry.estimatetEffort"
              v-focus
              :disabled="!authenticationHasRole('projectmanager')"
              class="form-control"
              type="number"
            >
            <div class="m-0 mb-3" />
            <label>Role</label>
            <ComboBox
              v-model="entry.role"
              :data-source="roleHeaders"
              :disabled="!authenticationHasRole('projectmanager')"
              :placeholder="'Select a role...'"
              class="form-control"
            />
            <div class="m-0 mb-3" />
            <label>{{ $t('description') }}</label>
            <textarea
              v-model="entry.description"
              v-focus
              :disabled="!authenticationHasRole('projectmanager')"
              class="form-control"
              type="text"
              rows="8"
            />
            <hr class="m-0 mb-3">
          </div>
        </form>
      </template>
      <button
        v-if="!openCalendarAddModal && authenticationHasRole('projectmanager')"
        slot="button"
        class="btn btn-danger float-left mb-3"
        @click="deleteElement"
      >
        <font-awesome-icon
          class="mr-1"
          icon="trash"
        />
        <span>{{ $t('remove') }}</span>
      </button>
      <button
        v-if="entry && authenticationHasRole('projectmanager')"
        slot="button"
        class="btn btn-primary float-right mb-3"
        :disabled="!(entry.calendarWeek && newResourceEntry && newResourceEntry.isActive && ((!issueToggle && newProjectEntry && newProjectEntry.isActive) || (issueToggle && entry.jiraIssue)))"
        @click="saveElement"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>{{ $t('save') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-secondary float-right mb-3 mr-3"
        @click="$refs.calendarModal.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />
        {{ $t('cancel') }}
      </button>
      <button
        v-if="!openCalendarAddModal && authenticationHasRole('projectmanager')"
        slot="button"
        class="btn btn-success float-right mr-3"
        @click="cloneElement"
      >
        <font-awesome-icon
          class="mr-1"
          icon="copy"
        />
        <span>Clone</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from "sweet-modal-vue";
import { ComboBox } from '@progress/kendo-dropdowns-vue-wrapper';
import { mapGetters } from 'vuex';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import * as moment from 'moment';

export default {
  name: "ResourceProjectCalendar",
  components: {
    SweetModal,
    ComboBox,
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    dateTimeMixin,
    urlParseMixin,
    authenticationMixin
  ],
  props: {
    resourceView: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      openCalendarAddModal: false,
      entry: null,
      entries: [],
      loading: false,
      currentWeek: null,
      currentYear: null,
      selectedRoles: [],
      selectedTeams: [],
      selectedResources: [],
      selectedProjects: [],
      projects: [],
      resources: [],
      goLives: [],
      modalLoading: false,
      project: {},
      resource: {},
      editProject: true,
      editResource: true,
      newResourceEntry: null,
      newProjectEntry: null,
      weekCount: 0,
      issueToggle: false,
      childProjects: null
    };
  },
  watch: {
    editProject () {
      if (this.editProject && this.projects.length > 0) {
        this.project = this.projects[0];
      }
      else {
        this.project = {};
      }
    },
    editResource () {
      if (this.editResource && this.resources.length > 0) {
        this.resource = this.resources[0];
      }
      else {
        this.resource = {
          team: null
        };
      }
    },
    project () {
      this.childProjects = null;
      if(this.project.childIds) {
        var ids = this.project.childIds.split(';');
        var childs = this.projects.filter(x => ids.includes(x.id+''));
        if(childs.length > 0) {
          this.childProjects = childs;
        }
      }
    }
  },
  computed: {
    resourceTeams () {
      let teams = this.resources.filter(x => x.team != null).map(x => x.team);
      return [...new Set(teams)];
    },
    roleHeaders () {
      let roles = this.entries.filter(x => x.role != null).map(x => x.role);
      if(!roles) {
        return [];
      }
      return [...new Set(roles)];
    },
    resourceHeaders () {
      let resources = [];
      resources = this.entries.filter(x => x.description != 'AT Holiday').map(x => ({resource: x.resourceEntry, project: x.projectEntry}));
      if(this.selectedProjects.length > 0) {
        let ids = this.selectedProjects.map(x => x.id);
        let childIds = this.selectedProjects.filter(x => x.childIds).map(x => x.childIds);
        resources = resources.filter(x => x.project && (ids.includes(x.project.id) || childIds.some(c => c.split(';').includes(x.project.id+''))));
      }
      if(this.selectedTeams.length > 0) {
        resources = resources.filter(x => this.selectedTeams.includes(x.resource.team));
      }
      return [...new Set(resources.map(x => x.resource))].sort((a, b) => (a.name != null ? a.name : 'zzz').localeCompare(b.name != null ? b.name : 'zzz'));
    },
    resourceWithRoleHeaders () {
      let resources = [];
      if(this.selectedRoles.length == 0) {
       return this.resourceHeaders();
      }

      resources = this.entries.filter(x => x.description != 'AT Holiday' && this.selectedRoles.includes(x.role)).map(x => ({resource: x.resourceEntry, project: x.projectEntry, role: x.role}));
      if(this.selectedProjects.length > 0) {
        let ids = this.selectedProjects.map(x => x.id);
        let childIds = this.selectedProjects.filter(x => x.childIds).map(x => x.childIds);
        resources = resources.filter(x => x.project && (ids.includes(x.project.id) || childIds.some(c => c.split(';').includes(x.project.id+''))) && this.selectedRoles.includes(x.role));
      }
      if(this.selectedTeams.length > 0) {
        resources = resources.filter(x => this.selectedTeams.includes(x.resource.team));
      }

      return [...new Set(resources.map(x => JSON.stringify(({resource: x.resource, role: x.role}))))].map(x => JSON.parse(x)).sort((a, b) => (a.resource.name != null ? a.resource.name : 'zzz').localeCompare(b.resource.name != null ? b.resource.name : 'zzz'));
    },
    projectTableHeaders () { 
      let projects = [];
      if(this.selectedRoles.length == 0) {
        projects = this.entries.filter(x => !x.specialLeave).map(x => (({resource: x.resourceEntry, project: x.projectEntry})));
        
        if(this.selectedResources.length > 0) {
          let ids = this.selectedResources.map(x => x.id);
          projects = projects.filter(x => ids.includes(x.resource.id));
        }
        if(this.selectedTeams.length > 0) {
          projects = projects.filter(x => this.selectedTeams.includes(x.resource.team));
        }

        for(var pro of projects) {
          var parent = this.getParent(pro.project.id);
          if(parent) {
            pro.project.isSubtask1 = true;
            projects.push({project: parent});
          }
        }
        
        return [...new Set(projects.map(x => x.project).filter(x => !x.isSubtask1))].sort((a, b) => a.order - b.order || a.projectName.localeCompare(b.projectName));
      }

      projects = this.entries.filter(x => this.selectedRoles.includes(x.role) && !x.specialLeave).map(x => ({project: x.projectEntry, resource: x.resource, role: x.role}));
      if(this.selectedResources.length > 0) {
        let ids = this.selectedResources.map(x => x.id);
        projects = projects.filter(x => x.resource && ids.includes(x.resource.resourceId) && !x.specialLeave)
      }
      if(this.selectedTeams.length > 0) {
        projects = projects.filter(x => this.selectedTeams.includes(x.resource.team));
      }

        for(pro of projects) {
          parent = this.getParent(pro.project.id);
          if(parent) {
            pro.project.isSubtask1 = true;
            projects.push({project: parent, role: pro.role});
          }
        }
      
      return [...new Set(projects.filter(x => !x.project.isSubtask1).map(x => JSON.stringify(({project: x.project, role: x.role}))))].map(x => JSON.parse(x)).sort((a, b) => a.project.order - b.project.order || a.project.projectName.localeCompare(b.project.projectName));
    },
    ...mapGetters(['store_getJiraUrl']),
    calenderWeeks () {
      let weeks = [];
      weeks.push({
          week:moment().add(this.weekCount, 'w').isoWeek(),
          year: moment().add(this.weekCount, 'w').year(),
          date: moment().add(this.weekCount, 'w').startOf('isoweek').format('DD.MM.yyyy'),
          goLive: moment().add(this.weekCount, 'w').startOf('isoweek').format('yyyy-MM-DD')
        })
      for(var j = 1; j < 25; j++) {
        let mmnt = moment().add(this.weekCount, 'w').add(j * 7, 'd');
        weeks.push({
          week:mmnt.isoWeek(),
          year: mmnt.year(),
          date: mmnt.startOf('isoweek').format('DD.MM.yyyy'),
          goLive: mmnt.startOf('isoweek').format('yyyy-MM-DD')
        })
      }
      return weeks;
    }
  },
  created () {  
    this.currentWeek = moment().isoWeek();
    this.currentYear = moment().year();
    this.initRequest();
  },
  mounted () {
    if(this.$route.params.issue) {
      if(this.authenticationHasRole('projectmanager')) {
        this.issueToggle = true;
        this.openAddModal();
      }
      else {
        this.$snotify.error("You don't have the right permissions."); 
      }
    }
  },
  methods: {
    goBack () {
      this.weekCount -= 25; 
      this.getEntries();
    },
    goToday () {
      this.weekCount = 0; 
      this.getEntries();
    },
    goNext () {
      this.weekCount += 25; 
      this.getEntries();
    },
    initRequest () {
      this.loading = true;
       this.axios.all([
        this.axios.get('/OnCallDuty/GetProjectEntries'),
        this.axios.get('/OnCallDuty/GetResourceEntries')
      ]).then(
        this.axios.spread((projectResponse, resourceResponse) => {
          if (projectResponse && projectResponse.data) {
            this.projects = projectResponse.data;
            if(this.projects.length > 0) {
              this.project = this.projects[0];
            }
          }
          if (resourceResponse && resourceResponse.data) {
            this.resources = resourceResponse.data;
            if(this.resources.length > 0) {
              this.resource = this.resources[0];
            }
          }
        }
      ))
      .finally(() => {
        this.getEntries();
      });
    },
    getMilestones (week, year) {
      let milestones = this.goLives.filter(x => x.year == year && x.calendarWeek == week);
      return milestones;
    },
    projectLabel ({ projectName }) {
      return `${projectName}`
    },
    resourceLabel ({ name, username }) {
      return name ? name : username;
    },
    jiraHref: function (id) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ id }`);
    },
    hasChilds (id) {
      return this.projects.filter(x => x.childIds).some(x => x.childIds.split(';').includes(id+''));
    },
    getParent (id) {
      return this.projects.filter(x => x.childIds).find(x => x.childIds.split(';').includes(id+''));
    },
    getEntrySummary (entry) {
      if(entry.specialLeave) {
        return entry.specialLeave;
      }
      if(entry.jiraId && entry.projectName) {
        return entry.jiraId + '\n' + entry.projectName;
      }
      return entry.projectName;
    },
    openAddModalWithWeekAndYear (week, year, resource) {
      this.newResourceEntry = resource;
      this.newProjectEntry = null;
      if(this.authenticationHasRole('projectmanager')) {
        this.entry = {
          calendarWeek: week,
          year: year,
          resourceEntry: resource,
          resourceId: resource.id,
          role: null,
          repeatForWeeks: 1,
          jiraIssue: null
        };
        this.openCalendarAddModal = true;
        this.$refs.calendarModal.open();
      }
    },
     openAddModalWithWeekAndYearAndRole (week, year, resource) {
      this.newResourceEntry = resource.resource;
      this.newProjectEntry = null;
      if(this.authenticationHasRole('projectmanager')) {
        this.entry = {
          calendarWeek: week,
          year: year,
          role: resource.role,
          repeatForWeeks: 1,
          jiraIssue: null
        };
        this.openCalendarAddModal = true;
        this.$refs.calendarModal.open();
      }
    },
    openAddModalWithWeekAndYearProject (week, year, project) {
      this.newResourceEntry = null;
      this.newProjectEntry = project.project ? project.project : project;
      if(this.authenticationHasRole('projectmanager')) {
        this.entry = {
          calendarWeek: week,
          year: year,
          role: null,
          repeatForWeeks: 1,
          jiraIssue: null
        };
        this.openCalendarAddModal = true;
        this.$refs.calendarModal.open();
      }
    },
    cloneElement () {
      this.openCalendarAddModal = true;
    },
    openAddModal () {
      this.newResourceEntry = null;
      this.newProjectEntry = null;
      this.entry = {
        year: new Date().getUTCFullYear(),
        calendarWeek: this.currentWeek,
        repeatForWeeks: 1,
        jiraIssue: null
      };
      this.openCalendarAddModal = true;
      if(this.$route.params.issue) {
        this.entry.jiraIssue = this.$route.params.issue;
      }
      this.$refs.calendarModal.open();
    },
    openProjectsModal () {
      this.$refs.projectsModal.open();
      this.editProject = true;
      this.getProjects();
    },
    closeProjectsModal () {
      this.$refs.projectsModal.close();
    },
    openResourcesModal () {
      this.$refs.resourcesModal.open();
      this.editResource = true;
      this.getResources();
    },
    closeResourcesModal () {
      this.$refs.resourcesModal.close();
    },
    openEditModal (val) {
      this.newResourceEntry = val.resourceEntry;
      this.newProjectEntry = val.projectEntry;
      if(this.authenticationHasRole('projectmanager')) {
        this.entry = val;
        this.entry.jiraIssue = null;
        this.openCalendarAddModal = false;
        this.$refs.calendarModal.open();
      }
    },
    closeModal () {
      this.entry = null;
      this.$refs.calendarModal.close();
    },
    getEntry (week, year, ce) {
      return this.entries.filter(x => x.calendarWeek == week && x.year == year && (x.resourceId == ce.id || x.description == "AT Holiday"));
    },
    getEffortColor (arr) {
      let count = this.getEffort(arr);
      
      if(count >= 100) {
        return 'effortRed';
      }
      else if(count >= 80) {
        return 'effortYellow';
      }

      return '';
    },
    getEffort (arr) {
      let count = arr.reduce((a, b) => a + (b.estimatetEffort || 0), 0);
      count += arr.reduce((a, b) => a + (b.specialLeaveHours || 0), 0) * 100 / 38.5;
      count += (arr.filter(x => x.description == "AT Holiday").length * 7.7) * 100 / 38.5;
      return count;
    },
    getEntryWithRole (week, year, ce) {
      return this.entries.filter(x => x.calendarWeek == week && x.year == year && (x.resourceId == ce.resource.id || x.description == "AT Holiday") && (x.role == ce.role || x.specialLeave));
    },
    getProjectEntry (week, year, ce) {
      if(ce.project) {
        return this.entries.filter(x => ce.role == x.role && x.calendarWeek == week && x.year == year && (x.projectId == ce.project.id || x.description == "AT Holiday" || (x.projectEntry && ce.project.childIds && ce.project.childIds.split(';').includes(x.projectEntry.id+''))));
      }
      return this.entries.filter(x => x.calendarWeek == week && x.year == year && (x.projectId == ce.id || x.description == "AT Holiday" || (x.projectEntry && ce.childIds && ce.childIds.split(';').includes(x.projectEntry.id+''))));
    },
    getEntries () {
      this.loading = true;
      this.axios.post(`/OnCallDuty/GetResourceProjectCalendarEntries`, this.calenderWeeks)
        .then((response) => {
          if(response && response.data) {
            this.entries = response.data;
            for(var entry of this.entries) {
              if(entry.description != 'AT Holiday') {
                let pProject = this.projects.find(x => x.id == entry.projectId);
                entry.projectEntry = pProject;

                let pResource = this.resources.find(x => x.id == entry.resourceId);
                entry.resourceEntry = pResource;
              }
              else {
                entry.specialLeave = entry.project;
              }
            }
          }
          this.loading = false;
        })
        .finally(() => {
          this.getSpecialLeaves();
          this.getGoLives();
        });
    },
    getSpecialLeaves () {
      let request = {
        queries: this.calenderWeeks,
        resources: [...new Set(this.entries.map(x => x.resourceEntry))]
      }
      this.axios.post(`/OnCallDuty/GetSpecialLeaves`, request)
        .then((response) => {
          if(response && response.data) {
            for(var entry of response.data) {
              let pResource = this.resources.find(x => x.id == entry.resourceId);
              entry.resourceEntry = pResource;
            }

            this.entries = this.entries.concat(response.data);
          }
        });
    },
    getGoLives () {
      let startWeek = this.calenderWeeks[0];
      let endWeek = this.calenderWeeks[this.calenderWeeks.length - 1];
      this.axios.get(`/OnCallDuty/GetGoLive?start=${startWeek.goLive}&end=${endWeek.goLive}`)
        .then((response) => {
          if(response && response.data) {
           this.goLives = response.data;
          }
        });
    },
    async getProjects () {
      this.modalLoading = true;
      await this.axios.get(`/OnCallDuty/GetProjectEntries`)
        .then((response) => {
          if(response && response.data) {
            this.childProjects = null;
            this.projects = response.data;
            if(this.projects.length > 0) {
              this.project = this.projects[0];
              if(this.project.childIds) {
                var ids = this.project.childIds.split(';');
                var childs = this.projects.filter(x => ids.includes(x.id+''));
                if(childs.length > 0) {
                  this.childProjects = childs;
                }
              }
            }
          }
          this.modalLoading = false;
        });
    },
    addProject () {
      if(this.childProjects) {
        this.project.childIds = this.childProjects.map(x => x.id).join(';');
      }
      else {
        this.project.childIds = null;
      }

      this.axios.post(`/OnCallDuty/AddProjectEntry`, this.project)
        .then(() => {
          this.closeProjectsModal();
          this.initRequest();
        });
    },
    updateProject () {
      if(this.childProjects) {
        this.project.childIds = this.childProjects.map(x => x.id).join(';');
      }
      else {
        this.project.childIds = null;
      }
      
      this.axios.put(`/OnCallDuty/UpdateProjectEntry`, this.project)
        .then(() => {
          this.closeProjectsModal();
          this.initRequest();
        });
    },
    getResources () {
      this.modalLoading = true;
      this.axios.get(`/OnCallDuty/GetResourceEntries`)
        .then((response) => {
          if(response && response.data) {
            this.resources = response.data;
            if(this.resources.length > 0) {
              this.resource = this.resources[0];
            }
          }
          this.modalLoading = false;
        });
    },
    addResource () {
      this.axios.post(`/OnCallDuty/AddResourceEntry`, this.resource)
        .then(() => {
          this.closeResourcesModal();
          this.initRequest();
        });
    },
    updateResource () {
      this.axios.put(`/OnCallDuty/UpdateResourceEntry`, this.resource)
        .then(() => {
          this.closeResourcesModal();
          this.initRequest();
        });
    },
    syncProjects () {
      this.loading = true;
      this.axios.get(`/OnCallDuty/UpdateProjectSummaries`)
        .then(() => {
          this.initRequest();
        });
    },
    saveElement () {
      this.entry.resourceId = this.newResourceEntry.id;
      if(this.issueToggle) {
        this.entry.projectId = null;
      }
      else {
        this.entry.projectId = this.newProjectEntry.id;
        this.entry.jiraIssue = null;
      }
      if(this.openCalendarAddModal) {
        let arr = [];
        arr.push(this.entry);
        if(!this.entry.repeatForWeeks) {
          this.entry.repeatForWeeks = 1;
        }
        for(var i = 1; i < this.entry.repeatForWeeks; i++) {
          let copy = JSON.parse(JSON.stringify(this.entry));
          let mom = moment((this.entry.year).toString()).add(this.entry.calendarWeek + i, 'weeks');
          copy.calendarWeek = mom.isoWeek();
          copy.year = mom.year();
          arr.push(copy); 
        }
        this.axios.post(`/OnCallDuty/CreateResourceProjectCalendarEntry`, arr)
        .then(async () => {
          if(this.issueToggle) {
            await this.getProjects();
          }
          this.closeModal();
          this.getEntries();
        });
      }
      else {
        this.axios.put(`/OnCallDuty/UpdateResourceProjectCalendarEntry`, this.entry)
        .then(async () => {
          if(this.issueToggle) {
            await this.getProjects();
          }
          this.closeModal();
          this.getEntries();
        });
      }
    },
    deleteElement () {
      this.axios.delete(`/OnCallDuty/DeleteResourceProjectCalendarEntry?id=${this.entry.id}`)
        .then(() => {
          this.closeModal();
          this.getEntries();
        });
    }
  }
};
</script>

<style scoped>
.fc-h-event .fc-event-main  {
  cursor: pointer;
}
.resourceProjectCalendar {
  max-height: 83.5vh;
  overflow: scroll;
}
table
{
  width: auto;
  height:100%;
  table-layout:fixed;
  border-collapse: separate;
  border-spacing: 0;
}
td div {
  margin: 5px;
  background-color: aquamarine;
  border-radius: 5px;
}
td div.vacSick {
  background-color: rosybrown;
}
td div.mil {
  background-color: #00ff7db8;
}
td .ros {
  background-color: rosybrown;
}
td div.holiday{
  background-color: #bcb68f;
}
td
{
  text-align: center;
  height:70px;
  width:120px;
  border-right: 1px grey solid;
  border-bottom: 1px grey solid;
  /* border: 1px grey solid; */
  overflow:visible;
  position:relative;
}
table thead th {
  position: sticky;
  top: 0px;
  z-index: 2;
  text-align: center;
  height:70px;
  width:120px;
  border-right: 2px black solid;
  border-top: 2px black solid;
  border-bottom: 2px black solid;
  border-left: 0px;
  overflow:visible;
  background-color: #cfcfd3;
}
table thead th:last-child {
  border-right: 2px black solid; 
}
table thead th:first-child {
  border-left: 2px black solid;
  z-index: 3; 
  left:0px;
  max-width: 30px;
  min-width: 30px;
}

table thead th:nth-child(2) {
  z-index: 3; 
  left: 30px;
}

table tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
  text-align: center;
  height: 100%;
  border: 1px grey solid;
  overflow:visible;
}

.sticky-td {
  position: sticky;
  left: 30px;
  z-index: 1;
  background-color: white;  
}

th.cw {
  font-weight: bold;
  border-bottom: 2px black solid;
  border-left: 2px black solid;
  border-right: 2px black solid;
  border-top: 0px;
  min-width: 30px;
  max-width: 30px;
  background-color: #e3e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
}

td.currentWeek {
  background-color: #b0e0b0;
}
th.currentWeek {
  background-color: #b0e0b0;
}

td.entryTd .edit-icon {
  cursor: pointer;
}

td.entryTd .edit-icon {
  cursor: pointer;
}

td.entryTd.effortRed {
  background-color: #ff58589e;
}

td.entryTd.effortYellow {
  background-color: #ffb8229e;
}

td.entryTd .row:first-child {
  flex-wrap: nowrap;
  max-height: 40px;
  overflow: hidden;
}

.holup, .mil {
  max-height: 40px;
}

span.percentage {
  position: absolute;
    top: 5px;
    right: 5px;
}

div.first {
  margin-top: 25px;
}

.mil {
  overflow: hidden;
}

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>